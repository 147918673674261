import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY, UI_VERSION } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import jwt_decode from 'jwt-decode';

import getOS from 'utils/operationSystem'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async ({ userName, password }) => {
        try {
			const resp = await apiSignIn({ email:userName, password: password, version: UI_VERSION, os:getOS() })
			if (resp.data.Status=='Normal') {
				var decoded = jwt_decode(resp.data.Content);
				if(resp.data.Status) {
					// decoded kontrolünü kaldır if else gitsin normala dönerken
					/*if(decoded.position_no==4){
						return {
							status: 'failed',
							message: 'Web Panel’e sadece yöneticiler girebilmektedir.'
						}
					} else{
					}*/
					dispatch(onSignInSuccess(resp.data.Content))
					if(decoded.position_no<4){
						dispatch(setUser(resp.data.user || { 
							avatar: '', 
							userName: decoded.name_surname, 
							authority: [decoded.position_name], 
							positionNo: decoded.position_no,
							email: decoded.e_mail,
							permissions: [301]
						}))
					} else if(decoded.position_no>4){
						dispatch(setUser(resp.data.user || { 
							avatar: '', 
							userName: decoded.name_surname, 
							authority: [decoded.position_name], 
							positionNo: decoded.position_no,
							email: decoded.e_mail,
							permissions: [301]
						}))
					} else{
						dispatch(setUser(resp.data.user || { 
							avatar: '', 
							userName: decoded.name_surname, 
							authority: [decoded.position_name], 
							positionNo: decoded.position_no, 
							email: decoded.e_mail,
							permissions: [300]
						}))
					}
					// decoded kontrolünü kaldır
					/*
					if(decoded.position_no!=4){
						navigate(appConfig.firstLoginPath);
						return {
							status: 'success',
							message: 'Giriş yapılıyor.'
						}
					}
					*/
					navigate(appConfig.firstLoginPath);
					return {
						status: 'success',
						message: 'Giriş yapılıyor.'
					}
				}
				//const redirectUrl = query.get(REDIRECT_URL_KEY)
				//navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
			} else{
				return {
                    status: 'failed',
                    message: 'Lütfen kullanıcı adı ve şifrenizi kontrol edin.'
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.error || errors.toString()
            }
		}
    }

    const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

    const signOut = async () => {
		try {
			await apiSignOut()
			handleSignOut()
		} catch (errors) {
			handleSignOut()
		}
	}
    
    return {
        authenticated: token && signedIn,
        signIn,
        signOut
    }
}

export default useAuth